/* button css start */
button {
  &:focus {
    outline: none; } }

// BS defualt btn style
[class*="btn--"]:not(.btn--link, .btn--light, .btn--base) {
  color: #fff; }
.btn {
  padding: 12px 30px; }
.btn--primary {
  background-color: $primary;
  &:hover {
    background-color: darken($primary, 5%); } }
.btn--secondary {
  background-color: $secondary;
  &:hover {
    background-color: darken($secondary, 5%); } }
.btn--success {
  background-color: $success;
  &:hover {
    background-color: darken($success, 5%); } }
.btn--danger {
  background-color: $danger;
  &:hover {
    background-color: darken($danger, 5%); } }
.btn--warning {
  background-color: $warning;
  &:hover {
    background-color: darken($warning, 5%); } }
.btn--info {
  background-color: $info;
  &:hover {
    background-color: darken($info, 5%); } }
.btn--light {
  background-color: $light;
  &:hover {
    background-color: darken($light, 5%); } }
.btn--dark {
  background-color: $dark;
  color: #fff;
  &:hover {
    background-color: darken($dark, 5%);
    color: #fff; } }
.btn--link {
  color: $primary; }
.btn--base {
  background-color: $base-color;
  color: #000;
  &:hover {
    background-color: darken($base-color, 5%);
    color: #000; } }
.button-overlay--one {
  position: relative;
  z-index: 1;
  &:hover {
    &::before {
      @include transform(scaleX(1));
      transform-origin: bottom left; } }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: $base-color-two;
    z-index: -1;
    transition: transform .3s ease;
    @include transform(scaleX(0));
    transform-origin: bottom right;
    z-index: -1;
    @include border-radius(5px); } }
.button-overlay--two {
  position: relative;
  z-index: 1;
  &:hover {
    &::before {
      @include transform(scaleX(1));
      transform-origin: bottom left; } }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: $base-color;
    z-index: -1;
    transition: transform .3s ease;
    @include transform(scaleX(0));
    transform-origin: bottom right;
    z-index: -1;
    @include border-radius(5px); } }
.text-btn {
  padding: 0;
  color: $para-color;
  background-color: transparent; }

// outline btn
.btn-outline--primary {
  color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $primary;
    color: #ffffff; } }
.btn-outline--secondary {
  color: $secondary;
  border-color: $secondary;
  &:hover {
    background-color: $secondary;
    color: #ffffff; } }
.btn-outline--success {
  color: $success;
  border-color: $success;
  &:hover {
    background-color: $success;
    color: #ffffff; } }
.btn-outline--danger {
  color: $danger;
  border-color: $danger;
  &:hover {
    background-color: $danger;
    color: #ffffff; } }
.btn-outline--warning {
  color: $warning;
  border-color: $warning;
  &:hover {
    background-color: $warning;
    color: #ffffff; } }
.btn-outline--info {
  color: $info;
  border-color: $info;
  &:hover {
    background-color: $info;
    color: #ffffff; } }
.btn-outline--light {
  color: $light;
  border-color: $light;
  &:hover {
    background-color: $light;
    color: #ffffff; } }
.btn-outline--dark {
  color: $dark;
  border-color: $dark;
  &:hover {
    background-color: $dark;
    color: #ffffff; } }
.btn-outline--base {
  color: $base-color;
  border: 1px solid $base-color;
  &:hover {
    background-color: $base-color;
    color: #000; } }

// btn shadow
.btn-shadow--primary {
  box-shadow: 0 0 6px 1px rgba($primary, 0.35); }
.btn-shadow--secondary {
  box-shadow: 0 0 6px 1px rgba($secondary, 0.35); }
.btn-shadow--success {
  box-shadow: 0 0 6px 1px rgba($success, 0.35); }
.btn-shadow--danger {
  box-shadow: 0 0 6px 1px rgba($danger, 0.35); }
.btn-shadow--warning {
  box-shadow: 0 0 6px 1px rgba($warning, 0.35); }
.btn-shadow--info {
  box-shadow: 0 0 6px 1px rgba($info, 0.35); }
.btn-shadow--light {
  box-shadow: 0 0 6px 1px rgba($light, 0.35); }
.btn-shadow--dark {
  box-shadow: 0 0 6px 1px rgba($dark, 0.35); }
.btn-shadow--base {
  box-shadow: 0 0 6px 1px rgba($base-color, 0.35); }

.btn--capsule {
  @include border-radius(999px); }

.icon-btn {
  width: 25px;
  height: 25px;
  background-color: $base-color;
  color: #fff;
  @include border-radius(3px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff; } }
.btn--group {
  @include d-flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  *[class*="btn"] {
    margin: 5px 10px;
    align-items: center;
    &.d-flex {
      padding: 8px 35px; } }
  &.style--two {
    margin-left: -5px;
    margin-right: -5px;
    *[class*="btn"] {
      margin: 3px 5px; } } }
[class*="btn"] {
  &.btn-md {
    padding: 10px 20px; }
  &.btn-sm {
    padding: 6px 10px; } }
/* button css end */
