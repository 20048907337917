/* footer section css start */
.footer {
  background-color: $base-color-two;
  position: relative;
  overflow: hidden;
  border-top: 2px solid rgba($base-color, 0.35);
  .cta-area {
    padding-top: 100px;
    .container {
      border-bottom: 1px solid rgba(#ffffff, 0.15); }
    .cta-title {
      @media (max-width: 991px) {
        font-size: 28px; } } }
  &__top {
    padding-top: 100px;
    padding-bottom: 70px; }
  &__bottom {
    padding: 15px 0;
    border-top: 1px solid rgba(#ffffff, 0.15);
    position: relative;
    z-index: 1;
    &::after {
      @include positionTwo;
      background-color: darken($base-color-two, 10%);
      opacity: 0.7;
      z-index: -1; } } }
.footer-widget {
  @media (max-width: 575px) {
    padding: 20px;
    background-color: darken($base-color-two, 5%);
    @include border-radius(5px); }
  &__title {
    color: #ffffff;
    margin-bottom: 45px;
    position: relative;
    @media (max-width: 991px) {
      margin-bottom: 25px; }
    @media (max-width: 575px) {
      border-bottom: 1px solid $base-color;
      display: inline-block; } } }
@include keyframes (glowFlow) {
  0% {
    opacity: 0.25; }
  25% {
    opacity: 0.35; }
  50% {
    opacity: 0.45; }
  75% {
    opacity: 0.35; }
  100% {
    opacity: 0.25; } }
.footer-logo {
  img {
    max-height: 50px; } }
.footer-menu-list {
  li {
    &:first-child {
      a {
        padding-top: 0; } }
    &:last-child {
      a {
        padding-bottom: 0;
        border-bottom: none; } }
    a {
      color: #ffffff;
      padding: 10px 0;
      display: block;
      font-size: 14px;
      border-bottom: 1px solid rgba(#ffffff, 0.15);
      @include transition(all 0.3s);
      &:hover {
        color: $base-color;
        border-color: $base-color; } } } }
.footer-social-links {
  li {
    margin: 5px 7px;
    a {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: $base-color;
      @include border-radius(3px);
      @include transition(all 0.3s);
      &:hover {
        box-shadow: 0 5px 10px rgba($base-color, 0.25);
        color: #ffffff;
        @include transform(translateY(-3px)); } } } }
/* footer section css end */
