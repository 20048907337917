/* feature section css start */
.feature-section {
  background-color: darken($base-color-two, 5%);
  padding: 30px 0; }
.feature-card {
  @include d-flex;
  justify-content: center;
  &__icon {
    width: 85px;
    font-size: 72px;
    line-height: 1;
    text-shadow: 0 5px 5px rgba($base-color, 0.45);
    @include media(991px);
    width: auto;
    font-size: 48px; }
  &__content {
    @include media(991px) {
      width: 100%;
      text-align: center; }
    .counter-amount {
      font-size: 32px;
      @include media(991px) {
        font-size: 24px;
        margin-top: 15px; } }
    p {
      @include media(991px) {
        font-size: 14px; } } } }
/* feature section css end */

/* about section css start */
.info-card {
  @include d-flex;
  &__icon {
    width: 75px;
    height: 75px;
    font-size: 48px;
    background-color: $base-color-two;
    color: $base-color;
    @include border-radius(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    box-shadow: inset 0 0 10px $base-color; }
  &__content {
    width: calc(100% - 75px);
    padding-left: 25px;
    @include media(480px) {
      width: 100%;
      padding-left: 0;
      margin-top: 20px; } } }
.abount-video {
  @include media(991px) {
    margin-top: 50px; }
  .video-button {
    position: relative;
    display: inline-flex; } }
.rounded-thumb {
  @include border-radius(8px);
  overflow: hidden; }
/* about section css end */

/* package section css start */
.package-card {
  padding: 30px;
  border: 3px solid $base-color;
  background-color: darken($base-color-two, 5%);
  @include border-radius(8px);
  height: 100%;
  box-shadow: 0 0 15px rgba($base-color, 0.5);
  @include transition(all 0.3s);
  position: relative;
  &:hover {
    box-shadow: 0 5px 15px rgba($base-color, 0.5);
    @include transform(translateY(-5px)); }
  &__title {
    font-size: 18px;
    background-color: $base-color;
    color: #000;
    padding: 6px 25px;
    margin-top: -66px;
    @include border-radius(10px 10px 0 0); }
  &__features {
    li {
      padding: 10px 0;
      &::before {
        content: "\f058";
        font-family: 'Line Awesome Free';
        font-weight: 900;
        margin-right: 5px;
        color: $base-color;
        font-size: 18px; } } }
  &__range {
    font-family: $heading-font;
    font-weight: 600;
    font-size: 32px; } }
/* package section css end */


/* choose us section css start */
.choose-card {
  padding: 30px;
  background-color: $base-color;
  background-color: rgba(darken($base-color-two, 7%), 0.8);
  height: 100%;
  text-align: center;
  @include transition(all 0.3s);
  &:hover {
    box-shadow: 0 5px 10px rgba($base-color, 0.25);
    @include transform(translateY(-5px));
    .choose-card__icon {
      @include border-radius(50%); } }
  &__icon {
    width: 75px;
    height: 75px;
    background-color: $base-color-two;
    color: $base-color;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include border-radius(5px);
    box-shadow: inset 0 0 10px $base-color;
    @include transition(all 0.3s);
    i {
      font-size: 42px;
      line-height: 1; } }
  &__title {
    color: $base-color;
    margin-top: 30px;
    margin-bottom: 15px; } }
/* choose us section css end */


/* profit calculator section css start */
.profit-calculator {
  .form-control, select {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    @include border-radius(0);
    padding-left: 0;
    &:focus {
      box-shadow: none;
      border-color: $base-color; } }
  #profit_amount {
    background-color: transparent;
    border: none;
    font-size: 32px;
    font-weight: 700;
    text-align: center; } }
.profit-calculate-area {
  padding: 30px 50px;
  background-color: darken($base-color-two, 3%);
  @include media(480px) {
    padding: 30px; } }
.profit-amount-area {
  padding: 50px;
  background-color: darken($base-color-two, 6%);
  @include media(480px) {
    padding: 30px; } }
/* profit calculator section css end */


/* how work section css start */
.work-card {
  padding: 40px 30px 120px 30px;
  background-color: $base-color-two;
  position: relative;
  &__icon {
    width: 100px;
    height: 100px;
    background-color: darken($base-color-two, 5%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    @include border-radius(50%);
    box-shadow: inset 0 0 10px $base-color; }
  &__content {
    margin-top: 30px; }
  .step-number {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 80px;
    background-color: darken($base-color-two, 5%);
    @include border-radius(99px 99px 0 0);
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transform(translateX(-50%));
    box-shadow: 0 -5px 5px rgba($base-color, 0.45); } }
/* how work section css end */


/* testimonial section css start */
.testimonial-card {
  &__content {
    padding: 30px;
    background-color: darken($base-color-two, 5%);
    box-shadow: 0 0 2px 1px $base-color;
    @include border-radius(5px);
    position: relative;
    &::before {
      position: absolute;
      content: '';
      bottom: -11px;
      left: 27px;
      width: 20px;
      height: 20px;
      @include transform(rotate(45deg));
      background-color: $base-color;
      z-index: -1; } }
  &__client {
    @include d-flex;
    align-items: center;
    margin-top: 30px;
    .thumb {
      width: 75px;
      height: 75px;
      overflow: hidden;
      border: 3px solid $base-color;
      @include border-radius(50%);
      margin-left: auto;
      img {
        width: inherit;
        height: inherit;
        @include object-fit; } }
    .content {
      width: calc(100% - 75px);
      padding-left: 20px; }
    .name {
      font-size: 18px; }
    .designation {
      font-size: 14px; } }
  .ratings {
    i {
      font-size: 22px; } } }
.ratings {
  i {
    color: #e4b332;
    font-size: 14px; } }
.testimonial-slider {
  .slick-list {
    margin: -15px; }
  .single-slide {
    margin: 15px; }
  .slick-dots {
    margin-top: 30px;
    @include d-flex;
    justify-content: center;
    li {
      margin: 0 5px;
      &.slick-active {
        button {
          background-color: $base-color;
          width: 35px; } }
      button {
        font-size: 0;
        width: 20px;
        height: 6px;
        background-color: rgba($base-color, 0.5);
        @include border-radius(999px);
        @include transition(all 0.3s); } } } }
/* testimonial section css end */


/* team section css start */
.team-card {
  background-color: darken($base-color-two, 5%);
  padding: 15px;
  @include transition(all 0.3s);
  @include border-radius(5px);
  &:hover {
    box-shadow: 0 5px 10px 5px rgba($base-color, 0.5);
    @include transform(translateY(-5px)); }
  &__thumb {
    img {
      width: 100%;
      @include border-radius(5px); } }
  &__content {
    padding: 20px 15px 15px 15px; } }
/* team section css end */


/* data section css start */
#chart > * {
  color: #ffffff !important;
  fill: #ffffff !important; }
.apexcharts-xaxis-label {
  color: #ffffff !important; }
/* data section css end */


/* top investor section css start */
.border-top-1 {
  border-top: 1px solid rgba($base-color, 0.5); }
.investor-card {
  padding: 20px 30px 40px 30px;
  background-color: darken($base-color-two, 5%);
  @include border-radius(999px);
  text-align: center;
  width: 200px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @include transition(all 0.3s);
  &:hover {
    box-shadow: 0 5px 10px rgba($base-color, 0.25);
    @include transform(translateY(-5px)); }
  @include media(1360px) {
    width: 185px; }
  &__thumb {
    width: 135px;
    height: 135px;
    display: inline-block;
    overflow: hidden;
    @include border-radius(50%);
    @include media(1360px) {
      width: 120px;
      height: 120px; }
    img {
      width: inherit;
      height: inherit;
      @include object-fit; } }
  &__content {
    margin-top: 30px;
    .amount {
      margin-top: 5px; } } }

/* top investor section css end */

/* payment brand section css start */
.brand-item {
  text-align: center;
  img {
    display: inline-block;
    max-height: 90px; } }
/* payment brand section css end */


/* blog section css start */
.blog-card {
  padding: 15px;
  @include border-radius(8px);
  background-color: darken($base-color-two, 5%);
  @include transition(all 0.3s);
  &:hover {
    box-shadow: 0 5px 5px 1px rgba($base-color, 0.25);
    @include transform(translateY(-5px)); }
  &__thumb {
    max-height: 220px;
    overflow: hidden;
    @include border-radius(5px);
    img {
      width: 100%;
      @include border-radius(5px); } }
  &__content {
    padding: 20px 15px 15px 15px; }
  &__meta {
    margin: -5px -7px;
    li {
      margin: 5px 7px;
      font-size: 14px;
      i {
        color: $base-color;
        font-size: 18px; }
      a {
        color: $base-color; } } } }
/* blog section css end */


/* blog-details-setcion css start */
.blog-details-area {
  background-color: darken($base-color-two, 5%);
  padding: 30px 30px;
  @include border-radius(8px);
  .blog-details-thumb {
    margin-bottom: 30px; } }
.blog-details-content {
  .blog-details-title {}
  p {
    margin-top: 20px; }
  blockquote {
    margin-top: 20px;
    margin-bottom: 25px;
    padding: 30px;
    p {
      margin-top: 0;
      font-size: 18px;
      font-style: italic; } }
  .inner-title {
    margin-top: 20px; }
  .row {
    .inner-title {
      margin-top: 0; } }
  .cmn-list {
    @include d-flex;
    margin-right: -15px;
    li {
      width: 50%;
      padding-right: 15px;
      @media (max-width: 575px) {
        width: 100%; } }
    li+li {
      margin-top: 10px; } }
  .inner-thumb {
    @media (max-width: 991px) {
      margin-bottom: 20px; } } }
.blog-details-footer {
  margin-top: 40px;
  @include d-flex;
  justify-content: space-between;
  .share-caption {
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 5px; }
  .share-post-links {
    @include d-flex;
    align-items: center;
    margin: -5px -7px;
    li {
      margin: 5px 7px;
      a {
        padding: 6px 15px;
        color: #ffffff;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        @include border-radius(5px);
        &.twitter {
          background-color: #55acee; }
        &.facebook {
          background-color: #3b5998; }
        i {
          margin-right: 7px; } } } } }
.reply-btn {
  padding: 5px 20px;
  background-color: $base-color;
  color: #000;
  @include border-radius(3px);
  font-size: 14px;
  float: right;
  margin-top: -30px;
  &:hover {
    color: #ffffff; } }
.comments-area {
  margin-top: 50px;
  background-color: darken($base-color-two, 5%);
  padding: 30px 30px;
  @include border-radius(8px);
  .title {
    text-transform: capitalize;
    margin-bottom: 30px; } }
.comments-list {
  .single-comment {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0; }
    .thumb {
      @include flexWidth(80px);
      height: 80px;
      @include border-radius(50%);
      overflow: hidden; }
    .content {
      @include flexWidth(calc(100% - 80px));
      padding-left: 20px;
      @media (max-width: 480px) {
        @include flexWidth(100%);
        padding-left: 0;
        margin-top: 15px; }
      .name {
        text-transform: capitalize; }
      .date {
        font-size: 14px; }
      p {
        margin-top: 5px; } } } }

.comment-form-area {
  margin-top: 50px;
  background-color: darken($base-color-two, 5%);
  padding: 30px 30px;
  @include border-radius(8px);
  .title {
    margin-bottom: 30px; }
  .comment-form {
    .form-group {
      margin-bottom: 30px; }
    input, textarea, button {
      @include border-radius(5px);
      border-color: rgba(#ffffff, 0.1);
      &:focus {
        border-color: $base-color; } }
    .submit-btn {
      text-transform: none; } } }

/* blog-details-setcion css end */


/* subscribe section css start */
.subscribe-wrapper {
  padding: 50px;
  @include border-radius(8px);
  background-color: $base-color-two;
  box-shadow: 0 0 10px rgba($base-color, 0.5);
  @media (max-width: 575px) {
    padding: 30px; } }
.subscribe-form {
  @include d-flex;
  justify-content: space-between;
  @include media(420px) {
    justify-content: flex-end; }
  .form-control {
    width: calc(100% - 125px);
    border: none;
    border-bottom: 1px solid rgba($base-color, 0.5);
    padding-left: 0;
    @include border-radius(0);
    @include media(420px) {
      width: 100%;
      margin-bottom: 15px; }
    &:focus {
      box-shadow: none;
      border-color: $base-color; } }
  .subscribe-btn {
    width: 113px; } }
/* subscribe section css end */


/* account section css start */
.account-wrapper {
  background-color: darken($base-color-two, 5%);
  box-shadow: 0 5px 25px 1px rgba(#000, 0.15);
  @include d-flex;
  @include border-radius(8px);
  overflow: hidden;
  .left {
    width: 45%;
    @include media(991px) {
      display: none; } }
  .right {
    width: 55%;
    padding: 70px 50px;
    @include media(991px) {
      width: 100%; }
    @include media(480px) {
      padding: 50px 30px; }
    @include media(380px) {
      padding: 30px 15px; }
    .inner {
      width: 80%;
      margin: 0 auto;
      @include media(1199px) {
        width: 90%; }
      @include media(767px) {
        width: 100%; } } } }
.user-account-check {
  position: relative;
  cursor: pointer;
  .form-check-input {
    position: absolute;
    top: 10px;
    right: 20px;
    &:checked ~ label {
      border-color: $base-color;
      box-shadow: 0 5px 10px rgba(#000, 0.08);
      i {
        color: $base-color; } } }
  label {
    display: block;
    padding: 20px;
    background-color: #fff;
    border: 2px solid #e5e5e5;
    @include border-radius(5px);
    @include transition(all 0.3s);
    i {
      font-size: 72px;
      color: lighten($para-color, 30%);
      @include transition(all 0.3s); }
    span {
      display: block; } } }
/* account section css end */


/* contact section css start */
.single-info + .single-info {
  margin-top: 40px; }
.single-info {
  $icon: 85px;
  &__icon {
    width: $icon;
    height: $icon;
    font-size: calc(#{$icon} / 2);
    @include border-radius(5px);
    @include media(991px) {
      width: 85px;
      height: 85px; }
    @include media(380px) {
      width: 60px;
      height: 60px;
      font-size: calc(60px / 2); } }
  &__content {
    width: calc(100% - #{$icon});
    padding-left: 25px;
    a {
      color: #fff;
      &:hover {
        color: $base-color; } }
    .title {
      font-size: 28px;
      @include media(991px) {
        font-size: 24px; }
      @include media(380px) {
        font-size: 22px; } } } }
.contact-info-list {
  .single-info {
    padding: rem(20px) 0;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0; } } }
/* contact section css end */


/* dashboard section css start */
.d-widget {
  padding: 20px;
  background-color: darken($base-color-two, 5%);
  @include border-radius(10px);
  .icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $base-color;
    color: #000;
    font-size: 28px;
    @include border-radius(50%);
    box-shadow: 0 0 8px rgba($base-color, 0.35); }
  .content {
    width: calc(100% - 60px);
    padding-right: 15px; }
  .currency-amount {
    font-size: 28px;
    color: #fff; }
  .caption {
    font-size: 14px;
    color: #fff;
    margin-bottom: 6px; } }
/* dashboard section css end */


/* error-area css start */
.error-area {
  min-height: 100vh;
  @include d-flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  position: relative;
  .cmn-btn {
    position: relative;
    z-index: 9; } }

@-webkit-keyframes pulse {
  from {
    opacity: 0.5; }

  50% {
    opacity: 0.5; } }

@keyframes pulse {
  from {
    opacity: 0.5; }

  50% {
    opacity: 0.5; } }

#desc {
  margin: 2em 0 1em; }

.error-num {
  position: relative;
  font-size: 220px;
  font-family: $heading-font;
  letter-spacing: -0.01em;
  line-height: 1;
  color: white;
  -webkit-animation: colorSplit 1.25s steps(2, end) infinite;
  animation: colorSplit 1.25s steps(2, end) infinite;
  @media (max-width: 575px) {
    font-size: 120px; } }

.error-num__clip {
  position: relative;
  font-size: 220px;
  font-family: $heading-font;
  letter-spacing: -0.01em;
  line-height: 1;
  color: white;
  -webkit-animation: colorSplit 1.25s steps(2, end) infinite;
  animation: colorSplit 1.25s steps(2, end) infinite;
  @media (max-width: 575px) {
    font-size: 120px; }

  &::before {
    position: relative;
    font-size: 220px;
    font-family: $heading-font;
    letter-spacing: -0.01em;
    line-height: 1;
    color: white;
    -webkit-animation: colorSplit 1.25s steps(2, end) infinite;
    animation: colorSplit 1.25s steps(2, end) infinite;
    @media (max-width: 575px) {
      font-size: 120px; } } }

.error-num__clip-left {
  position: relative;
  font-size: 10rem;
  font-family: $heading-font;
  letter-spacing: 1px;
  color: white;
  -webkit-animation: colorSplit 1.25s steps(2, end) infinite;
  animation: colorSplit 1.25s steps(2, end) infinite; }

@-webkit-keyframes colorSplit {
  25% {
    text-shadow: -0.02em 0 0 $base-color, 0.025em 0 0 darken($base-color, 20%); }

  75% {
    text-shadow: -0.035em 0 0 $base-color, 0.04em 0 0 darken($base-color, 20%); } }

@keyframes colorSplit {
  25% {
    text-shadow: -0.02em 0 0 $base-color, 0.025em 0 0 darken($base-color, 20%); }

  75% {
    text-shadow: -0.035em 0 0 $base-color, 0.04em 0 0 darken($base-color, 20%); } }

.error-num__clip {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 10;
  color: #333;
  overflow: visible;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
  clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
  -webkit-animation: glitch 1s steps(2, start) infinite;
  animation: glitch 1s steps(2, start) infinite;

  &::before {
    position: absolute;
    top: 0;
    left: -2px;
    z-index: 10;
    color: #333;
    overflow: visible;
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
    clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
    -webkit-animation: glitch 1s steps(2, start) infinite;
    animation: glitch 1s steps(2, start) infinite; } }

.error-num__clip-left {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 10;
  color: #333;
  overflow: visible;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
  clip-path: polygon(0% 0%, 100% 0, 100% 25%, 0 25%, 0 30%, 100% 30%, 100% 50%, 0 50%, 0 60%, 100% 60%, 100% 65%, 0 65%, 0 80%, 100% 80%, 100% 85%, 0 85%, 0% 0%);
  -webkit-animation: glitch 1s steps(2, start) infinite;
  animation: glitch 1s steps(2, start) infinite; }

@-webkit-keyframes glitch {
  30% {
    left: 0; }

  to {
    left: 0; } }

@keyframes glitch {
  30% {
    left: 0; }

  to {
    left: 0; } }

.error-num__clip::before, .error-num__clip-left::before {
  content: '404';
  left: 0.05em;
  color: white;
  z-index: 9;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 26%, 0 26%, 0 29%, 100% 29%, 100% 51%, 0 51%, 0 59%, 100% 59%, 100% 66%, 0 66%, 0 79%, 100% 79%, 100% 86%, 0 86%, 0% 0%);
  clip-path: polygon(0% 0%, 100% 0, 100% 26%, 0 26%, 0 29%, 100% 29%, 100% 51%, 0 51%, 0 59%, 100% 59%, 100% 66%, 0 66%, 0 79%, 100% 79%, 100% 86%, 0 86%, 0% 0%); }
/* error-area css end */
