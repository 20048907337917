/* reset css start */
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&family=Exo&family=Playfair+Display&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;

//font-family: 'Ubuntu', sans-serif;

$heading-font: 'Ubuntu', sans-serif;
$para-font: 'Ubuntu', sans-serif;

html {
  scroll-behavior: smooth; }
body {
  position: relative;
  font-family: $para-font;
  color: $para-color;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  // background-color: #0E0E32
  background-color: #14233c; }
img {
  max-width: 100%;
  height: auto; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }
button {
  cursor: pointer; }
*:focus {
  outline: none; }
button {
  border: none; }
button:focus {
  outline: none; }
span {
  display: inline-block; }
a:hover {
  color: $base-color; }
/* reset css end */
