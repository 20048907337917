
$heading-color: #ffffff;
$para-color: #ffffff;

$base-color: #02dcce;
// $base-color-two: #062c4e
$base-color-two: #14233c;

// bg-color
$bg-one: #0E0E32;
$bg-two: #1A1A3D;

// border-color
$border-color: rgba($base-color,0.45);

// box-shadow
$shadow: 0px 25px 40px 0px rgba(51, 51, 51, 0.1);
$shadow-two: 0px 0px 15px 0px rgba(51, 51, 51, 0.05);
$shadow-three: 0px 25px 40px 0px rgba(51, 51, 51, 0.2);


// color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #10163A;
$light: #eef4ff;
$muted: #cccccc;
