/*
  @mixin ===================================================
                Table of Content
  @mixin ===================================================
  1)  reset css
  2)  global css
  3)  table css
  4)  button css
  5)  form css
  6)  keyframes css
  7)  header
  8)  hero css
  9)  inner-hero css
  10) cureency section css
  11) about section css
  12) package section css
  13) choose us section css
  14) profit calculator section css
  15) how work section css
  16) testimonial section css
  17) team section css
  18) data section css
  19) top investor section css
  20) cta section css
  21) payment brand section css
  22) blog section css
  23) blog-details-section css
  24) subscribe section css
  25) account section css
  26) contact-wrapper css
  27) dashboard section css
  28) sidebar css
  29) footer
  */

// abstracts sass
@import "./abstracts/mixins";
@import "./abstracts/variables";


// base sass
@import "./base/base";
@import "./base/typography";


// components sass
@import "./components/table";
@import "./components/buttons";
@import "./components/form";
@import "./components/keyframes";


// layout sass
@import "./layout/header";
@import "./layout/hero-section";
@import "./layout/sections";
@import "./layout/sidebar";
@import "./layout/footer";
