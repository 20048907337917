/* hero css start */
.hero {
  padding-top: 270px;
  padding-bottom: 210px;
  position: relative;
  background-position-y: top;
  position: relative;
  @media (max-width: 1199px) {
    padding-top: 200px;
    padding-bottom: 130px; }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $base-color-two;
    opacity: 0.8; }
  &__title {
    font-size: 72px;
    @media (max-width: 1199px) {
      font-size: 48px; }
    @media (max-width: 767px) {
      font-size: 36px; } }
  &__subtitle {
    font-size: 20px;
    color: $base-color; } }
/* hero css end */


/* inner-hero css start */
.inner-hero {
  padding-top: 200px;
  padding-bottom: 100px;
  position: relative;
  @media (max-width: 1199px) {
    padding-top: 150px;
    padding-bottom: 70px; } }
/* inner-hero css end */
